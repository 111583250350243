/* Header search */

.header .search_form{
    position: relative ;
}


.header .search_form #search{
        background: #fafafa;
        border: 1px solid #ddd;
        min-width: 250px;
        width: 100%;
        outline: none;
        text-indent: 5px;
        border-radius: 3px;


}



.header .search_form #search_icon{
    position: absolute ;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}