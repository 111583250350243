/* Status */

.status{
    background-color: white;
    box-shadow: 0 0 5px #ddd;
    padding: 20px;
    border-radius: 5px;
}

.status .statusBtn{
background-color: #f1f1f1;
border: none;
outline: none;
border-radius: 30px;
color: #555;
padding: 0 10px;
text-align: left;
margin: 0 5px;
}

.status .statusBtn:hover{
    background-color: #ddd;
}