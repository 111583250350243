
/* Comments  */

.comment_display{
    padding:10px 25px;
}

.comment_card .comment_content{
    background: white;
    padding: 7px;
    border-radius: 10px;
    border-top-left-radius: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;


}

.comment_content span{
word-break: break-all;
}
.comment_card .comment_content .readMore{
    cursor: pointer;
    color: #128ced;
}

.comment_card .comment_content .nav-item{
    cursor: pointer;
    opacity: 0;
}

.comment_card .comment_content:hover .nav-item{
    opacity: 1;
}

.comment_card .comment_content .dropdown-menu{
    position:absolute;
    right: 0;
    left: inherit;
}

.comment_card .comment_content .dropdown-item{
   display: flex;
   margin: 10px 0px;
}

.comment_card .comment_content textarea{
    width: 100%;
    border: none;
    outline: none;
    background: #f1f1f1;
}