.goog-te-banner-frame.skiptranslate {
  display: none !important;
}
body {
  top: 0px !important;
}

.goog-logo-link {
  display: none !important;
}

.goog-te-gadget {
  color: transparent !important;
}

.goog-te-combo {
    width: 100px;
    height: 25px;
    font-size: 15px;
}