.messagexyz{
    width:100%;
    height:calc(100vh-100px);
    border:1px solid #ddd;
    margin-top: 15px;
    border-radius: 3px;
    background: #fbfbfb;
}
.message_header{
    width:100%;
    height:60px;
    border-bottom:1px solid #ddd;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f8f8f8;
}

.message_header input{
    flex: 1;
    height: 100%;
    border: none;
    outline: none;
    background: #f8f8f8;
    padding: 0 5px;
}


.message_chat_list{
    width:100%;
    height:calc(100%-60px);
    overflow-y: auto;

}

.message_chat_list .message_user{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    border:1px solid #f3f3f3;
    color: #555;
    cursor: pointer;
}

.message_chat_list .message_user:hover{
    text-decoration: none;
}

.message_chat_list .message_user .fa-circle{
    font-size: 8px;
    color: #aaa;
}


.message_chat_list .message_user .fa-circle.active{
    font-size: 8px;
    color: forestgreen;
}


/* chat input */
.chat_input{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    border: 1px solid #ddd;
}

.chat_input input{
    width: 100%;
    height: 49px;
    border: none;
    outline: none;

}

.chat_input button{
    border: none;
    outline: none;
    background-color: white;
}


/* chat container */

.chat_container{
    width: 100%;
    height: calc(100%-110px);
    overflow-y:auto ;
    padding: 0 10px;
}

.chat_display{
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}


.chat_row{
    display: grid;
    grid-template-columns: 70%;
    margin: 10px;
}


.your_message{
    justify-content: end;
    justify-items: end;
}


.other_message{
    justify-content: start;
    justify-items: start;
}

.chat_text
{
    padding:9px 14px;
    margin-bottom: 5px;
}

.chat_time{
    font-size: 13px;
    color: #777;
}

.your_message .chat_text{
    background-color: #128CED;
    color: white;
    border: 1px solid #128CED;
    border-radius: 14px 14px  14px;
}

.other_message .chat_text{
    background-color: white;
    color: black;
    border: 1px solid #ddd;
    border-radius: 14px 14px 0 14px;
}

.chat_title{
    margin-bottom: 3px;
}