/*!
 * Start Bootstrap - Simple Sidebar (https://startbootstrap.com/template/simple-sidebar)
 * Copyright 2013-2020 Start Bootstrap
 * Licensed under MIT (https://github.com/StartBootstrap/startbootstrap-simple-sidebar/blob/master/LICENSE)
 */

 #wrapper {
    overflow-x: hidden;
 }

#sidebar-wrapper {
	background: #128ced !important;
	color:#ffffff;
  min-height: 100vh;
  margin-left: -15rem;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 0.25rem;
    font-size: 1.2rem;
    text-align: center;
}
#sidebar-wrapper .sidebar-heading h3 {
    color:#ffffff;
	font-size:14px;
	margin-bottom:0;
}

#sidebar-wrapper .list-group {
  width: 15rem;
}

#page-content-wrapper {
  min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}
.sidebar-heading {
width: 100%;
  position: relative;
}
.sidebar-heading img {
	margin-bottom:10px;
}
.sidebar-heading i {
position: absolute;
top: 20px;
right: 60px;
border-radius: 50%;
padding: 11px;
height: 30px;
width: 30px;
display: flex !important;
align-items: center;
justify-content: center;
background-color: white;
color: cornflowerblue;
box-shadow: 0 0 10px 1px #ccc;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -15rem;
  }
}
